<template>
  <div class="page">  
    <logout v-if="showLogout" v-on:close-logout="showLogout = false" id="router"/>
    <div class="dashboard">
      <div class="side-nav" :style="showSide ? 'flex: 1': 'flex: 0.1'">
        <side-nav v-on:toggle-side-bar="showSide = !showSide" v-on:open-logout="showLogout = true"></side-nav>
      </div>
      <div class="page-body" :style="showSide ? 'flex: 4.5': 'flex: 5'">
        <router-view></router-view>

        <Transition name="slide-fade">
          <div class="message flex" v-if="$store.state.auth.generalMessage">
            <span :class="$store.state.auth.generalMessage.type == 1 ? 'text-success' : 'text-error'">{{ $store.state.auth.generalMessage.text }}</span>
            <span 
            @click="$store.state.auth.generalMessage = ''"
            class="flex-center" 
            :class="$store.state.auth.generalMessage.type == 1 ? 'cancel-btn-success' : 'cancel-btn-error'"
            ><i class="fa fa-times"></i>
            </span>
          </div>
        </Transition>
      </div>
  </div>
  </div>
</template>

<script>
import SideNav from '@/components/SideNav.vue'
import Logout from '@/components/Logout.vue'

export default {
  data(){
    return {
      showSide: true,
      showLogout: false,
      mobileView: true,
    }
  },
  components: {
    SideNav,
    Logout
  },
  methods: {
      handleView() {
          this.mobileView = window.innerWidth <= 500;
      }
    },
  created(){
    if(this.$route.name == 'app') {
      this.$router.push('/dashboard')
    }
    if(screen.width <= 1000) {
      this.showSide = false
    }

    this.handleView();
    window.addEventListener('resize', this.handleView);

    if(this.mobileView) {
      window.location.replace("https://mobile.linkmeup.us")
    }
  }
}
</script>

<style scoped>
  .dashboard {
    display: flex;
    overflow: hidden;
    height: 100vh;
  }

  .side-nav {
    flex: 1.5;
  }

  .page-body {
    flex: 4.5;
    background: #f3f3f3;
    height: 100vh;
    overflow: auto;
  }

  .header {
    padding: 0 20px;
  }

.message {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: #fff;
  align-items: stretch;
  width: 300px;
  border-radius: 2px;
  overflow: hidden;
}

  .message .text-success {
    flex: 7;
    padding: 10px;
    background-color: green;
    font-size: 16px;
  }

  .cancel-btn-success {
    flex: 1;
    background-color: #003300;
    cursor: pointer;
  }

  .cancel-btn-success:hover {
    background-color: #014b01;
  }

.message .text-error {
    flex: 7;
    padding: 20px 10px;
    background-color: #FF3333;
    font-size: 14px;
  }

  .cancel-btn-error {
    flex: 1;
    background-color: #7f1919;
    cursor: pointer;
  }

  .cancel-btn-error:hover {
    background-color: #cc2828;
  }

/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
<template>
  <div class="qrcode-page flex-center" v-if="show">
    <div class="modal-content" id="modal-content">
      
      <div class="flex-center body">
          <h3>Are you sure you want to log out?</h3>
          <div class="btns">
            <div>
                <Button @click="$emit('close-logout')" :style="{ color: '#fff', backgroundColor: '#159661', width: '106px', borderRadius: '50px', padding: '10px' }" text="No"/>
            </div>
            <div>
                <Button @click="logoutUser" :style="{ color: '#fff', backgroundColor: 'red', width: '100px', borderRadius: '50px', padding: '10px' }" text="Yes"/>            
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button.vue'

export default {
  components: {
    Button
  },
  data(){
    return {
      show: true
    }
  },
  methods: {
    logoutUser() {
      this.$store.dispatch('logoutUser')
    }
  }
}
</script>

<style scoped>
    .qrcode-page {
      position: fixed;
      z-index: 1;
      top: 0;
      left: 0;
      height: 100%;
      overflow: auto;
      width: 100%;
      background: rgba(0,0,0,0.3);
      padding: 20px 0px;
    }

    .modal-content {
      height: 630px;
      width: 40%;
      background: #fff;
      color: #262626;
      border-radius: 30px;
      padding: 20px;
      box-shadow: 0 0 6px 2px #00000035;
      /* margin-top: 10rem; */
    }

    .btns {
        display: flex;
        justify-content: space-between;
        padding: 30px 0;
        width: 300px;
    }

    .body {
        flex-direction: column;
        height: 600px;
    }

    
    .scroll::-webkit-scrollbar {
        width: 0.2em;
    }
    
    .scroll::-webkit-scrollbar-thumb {
    background-color: #d3d3d3;
    outline: none;
    }
</style>
<template>
  <aside class="side-nav scroll">
      <div class="logo flex" :style="showSide ? 'display: flex;' : 'display: block'">
        <router-link to="/dashboard" v-if="showSide">
            <img src="@/assets/lmu_logo.png" width="80">
        </router-link>
        <!-- <h2>Linkmeup</h2> -->
        <div class="menu-bar" @click="responsive">
            <i class="fa fa-align-left"></i>
        </div>
      </div>

      <div class="nav-links">
          <router-link to="/dashboard" active-class="active" class="link" exact :title="!showSide ? 'Dashboard' : ''">
            <i class="fa fa-th-large" aria-hidden="true"></i> <span v-if="showSide">Dashboard</span>
          </router-link>
          <router-link v-if="user.userRole == 'Administrator' || user.userRole == 'SubAdmin'" to="/profile" active-class="active" class="link" :title="!showSide ? 'Profile' : ''" exact><i class="fa fa-user-circle"></i> <span v-if="showSide">View Profile</span></router-link>
          <router-link v-if="user.userRole == 'Administrator' || user.userRole == 'SubAdmin'" to="/directory" active-class="active" class="link" exact :title="!showSide ? 'Directory' : ''"><i class="fa fa-folder" exact></i> <span v-if="showSide">Directory</span></router-link>
          <router-link to="/notification" active-class="active" class="link notif" exact :title="!showSide ? 'Notification' : ''">
            <i class="fa fa-bell" exact></i> 
            <span v-if="showSide">Notification</span>
            <span class="count">6</span>
          </router-link>
          <router-link v-if="user.userRole == 'Administrator'" to="/users" active-class="active" class="link" exact :title="!showSide ? 'Users' : ''"><i class="fa fa-users" exact></i> <span v-if="showSide">Users</span></router-link>
          <router-link to="/contacts" active-class="active" class="link" exact :title="!showSide ? 'Contacts' : ''"><i class="fa fa-address-book" exact></i> <span v-if="showSide">Contacts</span></router-link>
          <router-link to="/orders" active-class="active" class="link" exact :title="!showSide ? 'My Order' : ''"><i class="fa fa-address-card" exact></i> <span v-if="showSide">My Order</span></router-link>
          <router-link to="/history" active-class="active" class="link" exact :title="!showSide ? 'History' : ''"><i class="fa fa-history"></i> <span v-if="showSide">History</span></router-link>
          <router-link v-if="user.userRole == 'Administrator'" to="/blocked" active-class="active" class="link" exact :title="!showSide ? 'Blocked' : ''"><i class="fa fa-ban"></i> <span v-if="showSide">Blocked</span></router-link>
          <router-link v-if="user.userRole == 'Administrator'" to="/subscription" active-class="active" class="link" exact :title="!showSide ? 'Subscription' : ''"><i class="fa fa-shopping-bag" exact></i> <span v-if="showSide">Subscription</span></router-link>
          <router-link v-if="user.userRole == 'Administrator'" to="/settings" active-class="active" class="link" exact :title="!showSide ? 'Settings' : ''"><i class="fa fa-cog"></i> <span v-if="showSide">Settings</span></router-link>
          <router-link to="#" class="link" @click.prevent="$emit('open-logout')" :title="!showSide ? 'Logout' : ''"><i class="fa fa-sign-out"></i> <span v-if="showSide">Logout</span></router-link>
      </div>
  </aside>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters([
            'user'
        ])
    },
    data(){
        return {
            showSide: true
        }
    },
    created(){
        if(screen.width <= 1000) {
            this.showSide = false
        }
    },
    methods: {
        responsive(){
            this.showSide = !this.showSide
            this.$emit('toggle-side-bar')
        }
    }
}
</script>

<style scoped>
    .logo {
        text-align: center;
        margin-bottom: 20px;
        color: #fff;
        padding-bottom: 10px;
        border-bottom: 1.5px solid #c4c4c495;

    }

    .side-nav {
        padding: 15px 30px;
        /* text-align: center; */
        /* width: 300px; */
        background-color: #159661;
        height: 100vh;
        overflow: auto;
    }

    .side-nav h2 {
        text-align: center;
        margin-bottom: 30px;
        color: #fff;
        padding-bottom: 20px;
        border-bottom: 1.4px solid #ffffff6b;
    }

    .nav-links a {
        display: block;
        padding: 10px 20px;
        text-decoration: none;
        font-size: 14px;
        /* color: #fff; */
        font-weight: 500;
        outline-color: #fff;
        border-radius: 5px;
    }

    a {
        color: #fff;
    }

    .link {
        color: #fff;
    }

    .nav-links a:hover, .active {
        background: #fff;
        color: #159661;
    }

    .active {
        box-shadow: 0 0 3px 5px #02150721;
    }

    .nav-links span {
        margin-left: 10px;
    }

    .menu-bar {
        cursor: pointer;
        padding: 10px;
        border-radius: 3px 5px;
    }

    .menu-bar:hover {
        background: #ffffff4a;
    }

    .notif {
        position: relative;
    }

    .count {
        position: absolute;
        background-color: #ffffff;
        color: #159661;
        height: 18px;
        width: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        top: 11px;
        /* left: 15px; */
        right: 0;
        font-size: 12px;
        text-align: center;
        font-weight: 600;
    }

    .notif:hover .count {
        color: #159661;
    }

    .scroll::-webkit-scrollbar {
        width: 0.2em;
        margin-right: 2px;
    }

    .scroll::-webkit-scrollbar-thumb {
      background-color: #03221510;
      outline: none;
    }
</style>